import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import '../styles.scss'

const LinkedInIcon = ({ iconSize }) => {
  const iconStyles = classNames({
    'social-svg-icons': true,
    [`social-svg-icons--${iconSize}`]: iconSize,
  })

  return (
    <svg className={iconStyles} viewBox="0 0 23 24" title="LinkedIn">
      <path d="M 21.800781 0 L 2.199219 0 C 1 0 0 1 0 2.199219 L 0 21.800781 C 0 23 1 24 2.199219 24 L 21.800781 24 C 23 24 24 23 24 21.800781 L 24 2.199219 C 24 1 23 0 21.800781 0 Z M 7 20 L 3 20 L 3 9 L 7 9 Z M 5 7.699219 C 3.800781 7.699219 3 6.898438 3 5.898438 C 3 4.800781 3.800781 4 5 4 C 6.199219 4 7 4.800781 7 5.800781 C 7 6.898438 6.199219 7.699219 5 7.699219 Z M 21 20 L 17 20 L 17 14 C 17 12.398438 15.898438 12 15.601563 12 C 15.300781 12 14 12.199219 14 14 C 14 14.199219 14 20 14 20 L 10 20 L 10 9 L 14 9 L 14 10.601563 C 14.601563 9.699219 15.601563 9 17.5 9 C 19.398438 9 21 10.5 21 14 Z " fill="#6CCFF6" />
    </svg>
  )
}

LinkedInIcon.propTypes = {
  iconSize: PropTypes.string.isRequired,
}

export default LinkedInIcon
